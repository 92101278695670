<template>
  <div class="body fixed mobile">
    <!-- class: fixed, mobile -->
    <!-- Header -->
    <section class="header profile">
      <div class="icon iconButton">
        <span class="fXL bold">會員中心</span>
      </div>
      <NotificationBell @get-point="getPointHandler" />
    </section>
    <!-- Main -->
    <section class="main profile">
      <!-- 個人資訊 -->
      <div class="tag">
        <img v-if="displayUserImage()" :src="displayUserImage()" />
        <img v-else :src="profileImage">
        <div>
          <!-- <span class="title">桃園市民</span> -->
          <span>{{ displayName() }}</span>
        </div>
        <a @click.prevent="$router.push('/profile/edit')">
          <font-awesome-icon icon="fa-regular fa-pen-to-square" size="lg"></font-awesome-icon>
        </a>
      </div>

      <!-- 功能 -->
      <div v-if="enableStamp" class="functions flexH width">
        <a class="flexV center width" @click.prevent="$router.push('/record')">
          <img src="@/assets/icon/record.svg" />
          <span class="fM">{{ $t('交易紀錄') }}</span>
          <!-- <span class="fS"><img src="@/assets/icon/point.svg">{{ displayUserPointBalance }}</span> -->
        </a>
        <a v-if="enableTickets" class="flexV center width" @click.prevent="$router.push('/tickets')">
          <img src="@/assets/icon/ticket.svg" />
          <span class="fM">{{ $t('我的票券') }}</span>
          <!-- <span class="fS">{{ numberOfTickets }} {{ $t('張') }}</span> -->
        </a>
        <a
          class="flexV center width"
          @click.prevent="$router.push('/stamp-cards')"
        >
          <img src="@/assets/icon/stamp.svg" />
          <span class="fM">{{ $t('我的集章') }}</span>
          <!-- <span class="fS">{{ numberOfStampCards }} {{ $t('張') }}</span> -->
        </a>
      </div>
      <a
        class="invite flexH width rounded middle"
        v-show="displayReferralCode()"
        @click.prevent="popInvite()"
      >
        <span class="fM">使用<span class="primary">我的邀請碼</span>，邀請朋友！</span>
        <div class="img"><img :src="pointImage" /></div>
      </a>
      <!-- <a
        v-if="!enableStamp"
        class="point flexH between end"
        @click.prevent="$router.push('/record')"
      >
        <div class="flexH">
          <div class="img">
            <img src="@/assets/icon/point.svg" />
          </div>
          <div class="flexV">
            <span class="fM">紅利桃子</span>
            <span class="fXL primary"
              >{{ displayUserPointBalance
              }}<span class="fM primary">點</span></span
            >
          </div>
        </div>
        <div class="flexV" v-if="displayDate">
          <span class="fS">點數到期日</span>
          <span class="fS">{{ displayDate }}</span>
        </div>
      </a> -->
      <!-- 分類 -->
      <div class="category">
        <span class="title">會員服務</span>
        <div class="item">
          <a @click.prevent=" barcodePopupOpen = true">
            <div class="pic"><img src="@/assets/icon/barcode.svg"></div>
            <span>我的條碼</span>
          </a>
          <a @click.prevent="$router.push('/carrier/edit')">
            <div class="pic"><img src="@/assets/icon/carrier.svg"></div>
            <span>手機載具</span>
          </a>
          <a v-if="!disableMunicipaInfo" @click.prevent="$router.push('/websites')">
            <div class="pic"><img src="@/assets/icon/website.svg"></div>
            <span>市政資訊</span>
          </a>
          <a @click.prevent="$router.push('/code-exchange')">
            <div class="pic"><img src="@/assets/icon/serial.svg"></div>
            <span>序號兌換</span>
          </a>
          <a @click.prevent="$router.push('/bind-ddpay')">
            <div class="pic"><img src="@/assets/icon/ddpay.svg"></div>
            <span>{{ $t("message.DDPAY") }} </span>
          </a>
          <!-- <a  href="https://lihi3.me/z807p" target="_blank">
            <div class="pic"><img src="@/assets/icon/store-info.svg"></div>
            <span>市民卡優惠</span>
          </a> -->
        </div>
      </div>
      <!-- 分類 -->
      <div class="category">
        <span class="title">特約商店</span>
        <div class="item">
          <a v-if="!disableStoreApplication" @click.prevent="$router.push('/appoint-store')">
            <div class="pic"><img src="@/assets/icon/store-new.svg"></div>
            <span>特約商店申請</span>
          </a>
          <a :href="storeAdminHost">
            <div class="pic"><img src="@/assets/icon/store-admin.svg"></div>
            <span>特店後台管理</span>
          </a>
          <a v-if="storeContractUrl" :href="storeContractUrl" target="_blank">
            <div class="pic"><img src="@/assets/icon/contract.svg"></div>
            <span>特店合約</span>
          </a>
        </div>
      </div>
      <!-- 分類 -->
      <div v-if="enableCityCard" class="category">
        <span class="title">市民卡資訊</span>
        <div class="item">
          <a  href="https://lihi3.me/z807p" target="_blank">
            <div class="pic"><img src="@/assets/icon/store-info.svg"></div>
            <span>市民卡優惠</span>
          </a>
          <a v-if="storeApplicationInfoUrl" :href="storeApplicationInfoUrl" target="_blank">
            <div class="pic"><img src="@/assets/icon/contract.svg"></div>
            <span>廠商申請資料</span>
          </a>
        </div>
      </div>
      <!-- 分類 -->
      <div class="category">
        <span class="title">系統設定</span>
        <div class="item">
          <a v-if="onlineCustomerService" :href="onlineCustomerService">
            <div class="pic"><img src="@/assets/icon/cs.svg"></div>
            <span>線上客服</span>
          </a>
          <a v-if="false" href="setting.html">
            <div class="pic"><img src="@/assets/icon/setting.svg"></div>
            <span>設定</span>
          </a>
          <a @click.prevent="logout()">
            <div class="pic"><img src="@/assets/icon/logout.svg"></div>
            <span>登出</span>
          </a>
        </div>
      </div>
      <!-- <div class="function flexH rounded">
        <a
          class="flexH width middle"
          @click.prevent=" barcodePopupOpen = true"
        >
          <div class="icon primary">
            <font-awesome-icon icon="fa-solid fa-barcode"></font-awesome-icon>
          </div>
          <span class="fM">我的條碼</span>
        </a>
        <div class="lineV"></div>
        <a
          class="flexH width middle"
          @click.prevent="$router.push('/carrier/edit')"
        >
          <div class="icon primary">
            <font-awesome-icon icon="fa-solid fa-receipt"></font-awesome-icon>
          </div>
          <span class="fM">手機載具</span>
        </a>
      </div> -->
      <div class="menu flexV width">
        <!-- <a
            class="flexH width between"
            @click.prevent="$router.push('/profile/edit')"
        >
        <div class="flexH">
            <div class="img">
                <img src="@/assets/icon/edit.svg" />
            </div>
            <span class="fM">編輯個人資料</span>
        </div>
        <div class="icon gray">
            <font-awesome-icon
                icon="fa-solid fa-angle-right"
            ></font-awesome-icon>
        </div>
        </a> -->
        <!-- <a
          class="flexH width between"
          @click.prevent="$router.push('/appoint-store')"
        >
          <div class="flexH">
            <div class="img">
              <img src="@/assets/icon/store-new.svg" />
            </div>
            <span class="fM">特約商店申請</span>
          </div>
          <div class="icon gray">
            <font-awesome-icon
              icon="fa-solid fa-angle-right"
            ></font-awesome-icon>
          </div>
        </a> -->
        <!-- <div class="lineH"></div>
        <a class="flexH width between">
            <div class="flexH">
                <div class="img">
                    <img src="@/assets/icon/store-admin.svg" />
                </div>
                <span class="fM">特約商店後台管理</span>
            </div>
            <div class="icon gray">
                <font-awesome-icon
                    icon="fa-solid fa-angle-right"
                ></font-awesome-icon>
            </div>
        </a> -->
        <!-- <div class="lineH"></div>
        <a class="flexH width between" href="https://lihi1.com/Lfmnr" target="_blank">
            <div class="flexH">
                <div class="img">
                    <img src="@/assets/icon/contract.svg" />
                </div>
                <span class="fM">特約商店合約</span>
            </div>
            <div class="icon gray">
                <font-awesome-icon
                    icon="fa-solid fa-angle-right"
                ></font-awesome-icon>
            </div>
        </a>
        <div class="lineH"></div>
        <a class="flexH width between" href="https://lin.ee/4LypwdI">
            <div class="flexH">
                <div class="img">
                    <img src="@/assets/icon/cs.svg" />
                </div>
                <span class="fM">線上客服</span>
            </div>
            <div class="icon gray">
                <font-awesome-icon
                    icon="fa-solid fa-angle-right"
                ></font-awesome-icon>
            </div>
        </a> -->
        <!-- <div class="lineH"></div>
        <a class="flexH width between">
            <div class="flexH">
                <div class="img">
                    <img src="@/assets/icon/setting.svg" />
                </div>
                <span class="fM">設定</span>
            </div>
            <div class="icon gray">
                <font-awesome-icon
                    icon="fa-solid fa-angle-right"
                ></font-awesome-icon>
            </div>
        </a> -->
        <!-- <div class="lineH"></div>
        <a class="flexH width between" @click.prevent="logout()">
          <div class="flexH">
            <div class="img"><img src="@/assets/icon/logout.svg"></div>
            <span class="fM">登出</span>
          </div>
          <div class="icon gray">
            <font-awesome-icon
              icon="fa-solid fa-angle-right"
            ></font-awesome-icon>
          </div>
        </a> -->
      </div>
      

      <div class="flexH width rounded middle">
        <span class="fM gray">v{{ version }}</span>
      </div>
    </section>

    <!-- Popup (邀請碼) -->
    <section id="popInvite" class="popup popInvite profile">
      <div class="popBody">
        <a class="close"
          ><font-awesome-icon icon="fas fa-times"></font-awesome-icon
        ></a>
        <section class="popHeader">
          <span class="fL primary">我的邀請碼</span>
        </section>
        <section class="popMain">
          <div class="flexH">
            <div class="img">
              <img src="@/assets/icon/invite.svg" />
            </div>
            <!-- <div class="flexV">
                            <span class="fM">成功邀請朋友</span>
                            <span class="fL third">10</span>
                        </div> -->
          </div>
        </section>
        <section class="popFooter">
          <a class="button rounded code white" @click.prevent="copy()">
            <div class="flexH">
              <div class="icon">
                <font-awesome-icon icon="fa-solid fa-check"></font-awesome-icon>
              </div>
              <span v-show="isCopied" class="fS gray">已複製</span>
            </div>
            <span class="fM">{{ displayReferralCode() }}</span>
            <input
              type="hidden"
              id="referral-code"
              :value="generateReferralCode()"
            />
          </a>
          <a class="button rounded line" :href="shareWithLINEUrl()">
            <div class="img">
              <img src="@/assets/icon/line.svg" />
            </div>
            <span class="fM">Line邀請好友</span>
          </a>
        </section>
      </div>
    </section>
    <!-- Popup (Code) -->
    <BarcodePopup
      v-if="barcodePopupOpen"
      @closePopup="barcodePopupOpen = false"
    />
  </div>
</template>

<script>
import moment from 'moment-timezone';
import { mapState, mapGetters } from 'vuex';
import NotificationBell from '@/components/NotificationBell.vue';
import BarcodePopup from '../components/BarcodePopup.vue';

export default {
  name: 'Profile',
  components: {
    NotificationBell,
    BarcodePopup,
  },
  data() {
    return {
      apiHost: process.env.VUE_APP_API_HOST,
      merchantId: process.env.VUE_APP_MERCHANT_ID,
      isCopied: false,
      appHost: process.env.VUE_APP_HOST + process.env.BASE_URL,
      storeAdminHost: process.env.VUE_APP_STORE_ADMIN_URL,
      myReferralCode: '',
      isNotificationBar: false,
      notificationBarContent: {
        title: '',
        message: '',
        time: '',
        points: '',
        reset: function () {
          this.title = '';
          this.message = '';
          this.time = '';
          this.points = '';
        },
      },
      firstNotification: {},
      userData: {},
      stampCards: [],
      tickets: [],
      barcodePopupOpen: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!
    console.log(to, from);
    next((vm) => {
      let that = vm;
      console.log('user: ', that.user);
      if (that.user && that.user.userId) {
        vm.getUserPoints()
          .then((res) => {
            that.$store.commit('updateUserPoints', res);
          })
          .catch((err) => {
            console.log('err: ', err);
            next('/error');
          });
        vm.$store.commit(
          'updateUserInfo',
          Object.assign({}, vm.userInfo, {
            myReferralCode: vm.user.user.myReferralCode,
          })
        );
        vm.myReferralCode = vm.user.user.myReferralCode;
      } else {
        next('/error');
      }
    });
  },
  created() {},
  mounted() {
    // this.getUserData();
    // this.getStampCards();
    // this.getTickets();
  },
  computed: {
    ...mapState(['user', 'userInfo', 'currentPath']),
    ...mapGetters({
      userPoints: 'getTyUserPoints',
    }),
    enableTickets() {
      return process.env.VUE_APP_DISABLE_TICKETS ==='true' ? false : true;
    },
    profileImage() {
      return process.env.VUE_APP_PROFILE_D_IMAGE_PATH?.trim() ? require(`@/assets/${process.env.VUE_APP_PROFILE_D_IMAGE_PATH}`) : require("@/assets/img/profile-d.png");
    }, 
    pointImage() {
      return process.env.VUE_APP_POINT_IMAGE_PATH?.trim() ? require(`@/assets/${process.env.VUE_APP_POINT_IMAGE_PATH}`) : require("@/assets/icon/point.svg");
    }, 
    disableStoreApplication() {
      return process.env.VUE_APP_DISABLE_STORE_APPLICATION?.trim() === 'true' ? true : false;
    }, 
    disableMunicipaInfo() {
      return process.env.VUE_APP_DISABLE_MUNICIPAL_INFO?.trim() === 'true' ? true : false;
    },
    storeContractUrl() {
      return this.disableStoreApplication ? null : process.env.VUE_APP_STORE_CONTRACT_URL ? process.env.VUE_APP_STORE_CONTRACT_URL.trim() : 'https://lihi3.com/njcud';
    },
    storeApplicationInfoUrl() {
      return this.disableStoreApplication ? null : process.env.VUE_APP_STORE_APPLICATION_INFO_URL ? process.env.VUE_APP_STORE_APPLICATION_INFO_URL.trim() : 'https://lihi3.me/mEtlM';
    },
    onlineCustomerService() {
      return process.env.VUE_APP_DISABLE_ONLINE_CUSTOMER_SERVICE?.trim() === 'true' ? null : process.env.VUE_APP_ONLINE_CUSTOMER_SERVICE_URL ? process.env.VUE_APP_ONLINE_CUSTOMER_SERVICE_URL.trim() : 'https://lin.ee/4LypwdI';
    },
    displayDate() {
      return this.lastestEndOfPoint
        ? this.tsToData(this.lastestEndOfPoint.endTs)
        : '';
      // return this.tsToData(
      //     this.userPoints &&
      //         this.userPoints.pointPeriods &&
      //         this.userPoints.pointPeriods.length > 0
      //         ? this.userPoints.pointPeriods[0].endTs
      //         : null
      // );
    },
    version() {
      return process.env.PACKAGE_VERSION;
    },
    displayUserPointBalance() {
      return this.userPoints && this.userPoints.pointBalance
        ? this.userPoints.pointBalance
        : 0;
    },
    lastestEndOfPoint() {
      if (
        this.userPoints &&
        this.userPoints.pointPeriods &&
        this.userPoints.pointPeriods.length > 0
      ) {
        let sorted = [...this.userPoints.pointPeriods];
        sorted.sort(function (a, b) {
          return a.endTs - b.endTs;
        });
        const latestEndOfPoint = sorted.find((p) => p.endTs >= moment().unix());
        return latestEndOfPoint;
      }
      return null;
    },
    numberOfStampCards() {
      return this.stampCards ? this.stampCards.length : 0;
    },
    numberOfTickets(){
      let ticketSum = 0;
      this.tickets.forEach(product => {
          ticketSum += product.tickets.filter(
              ticket => ticket.status === 'pickedup'
          ).length;
      })
      return ticketSum;
    },
    enableStamp() {
      return process.env.VUE_APP_ENABLE_STAMP === 'true';
    },
    enableCityCard() {
      return process.env.VUE_APP_DISABLE_PROFILE_CITYCARD === 'true' ? false : true;
    },
    
  },
  methods: {
    dummyUserData() {
      let result = {
        memberId: 47,
        uuid: null,
        oauthId: 'U38a079aadef4a60ec5a6263112248b35',
        picture:
          'https://profile.line-scdn.net/0hNwmZpXdREVhQIzrAyWRuD2xmHzUnDRcQKEJdOHIlGmt1RFIHOEFXPSUiH2x4EFIHaBZebiErSW4p',
        idno: 'H223292882',
        name: 'Kay Lai',
        birthdate: '1988-03-05',
        phoneNumber: '0921185084',
        carrier: null,
        gender: 1,
        email: 'clearsky0305@gmail.com',
        fullAddress: '桃園市平鎮區',
        isTyCitizen: 1,
        createdTs: 1647945287,
      };
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ data: result });
        }, 300);
      });
    },
    callUserDataAPI() {
      var config = {
        method: 'get',
        url: `${this.apiHost}/identities/v1/merchants/${this.merchantId}/members/${this.user.userId}`,
        headers: {},
      };

      return this.$http(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          return response;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getUserData() {
      this.callUserDataAPI()
        // this.dummyUserData()
        .then((res) => {
          this.userData = res.data;
        })
        .catch((err) => {
          console.log('err: ', err);
          this.$router.push('/error');
        });
    },
    displayReferralCode() {
      return this.user.user.myReferralCode;
    },
    displayName() {
      return this.userInfo.name;
    },
    displayUserImage() {
      return this.userInfo ? this.userInfo.picture : '';
    },
    tsToData(ts) {
      return moment(ts * 1000).format('YYYY-MM-DD');
    },
    getUserPoints() {
      var config = {
        method: 'get',
        url: `${this.apiHost}/points/v1/merchants/${this.merchantId}/members/${this.user.userId}/points`,
        headers: {},
      };

      return this.$http(config)
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    popInvite() {
      var popInvite = document.getElementById('popInvite');
      var close = popInvite.getElementsByClassName('close')[0];
      close.onclick = function () {
        popClose();
      };
      window.onclick = function (event) {
        if (event.target == popInvite) {
          popClose();
        }
      };
      function popClose() {
        popInvite.style.display = 'none';
        document.body.style.overflow = 'scroll';
      }
      popInvite.style.display = 'flex';
      document.body.style.overflow = 'hidden';
    },
    copy() {
      let textToCopy = document.querySelector(`#referral-code`);
      console.log('referralCode: ', textToCopy.value);
      textToCopy.setAttribute('type', 'text');
      textToCopy.select();
      var successful = document.execCommand('copy');
      if (successful) {
        this.isCopied = true;
      }
      textToCopy.setAttribute('type', 'hidden');
    },
    generateReferralCode() {
      return this.myReferralCode;
    },
    generateReferralUrl() {
      return encodeURIComponent(
        `${this.appHost}?referralCode=${this.myReferralCode}&openExternalBrowser=1`
      );
    },
    shareWithLINEUrl() {
      return `https://line.me/R/share?text=${this.generateReferralUrl()}`;
    },
    getPointHandler(event) {
      console.log('get points: ', event);
      this.updateUserPoints();
    },
    updateUserPoints() {
      this.getUserPoints()
        .then((res) => {
          console.log('update user points!');
          this.$store.commit('updateUserPoints', res);
        })
        .catch((err) => {
          console.log('err: ', err);
          this.router.push('/error');
        });
    },
    logout() {
      qcsso
        .logout({
          logoutRedirectUri: this.appHost.substring(0, this.appHost.length - 1),
        })
        .then(() => console.log('success'))
        .catch((err) => console.log(err));
    },
    callGetStampCardsAPI() {
      let config = {
        // url: `${this.apiHost}/stamps/v1/merchants/${this.merchantId}/collector/${this.user.userId}/stamp-cards`
        url: `${this.apiHost}/stamps/v1/merchants/${this.merchantId}/stamp-cards?uuid=${this.user.userId}`,
      };
      console.log('config: ', config);
      return this.$http(config);
    },
    getStampCards() {
      this.callGetStampCardsAPI()
        .then((res) => {
          this.stampCards = res.data;
          console.log('callGetStampCardsAPI: ', res.data);
        })
        .catch((err) => console.log(err));
    },
    callGetTicketsAPI() {
      let config = {
          url: `${this.apiHost}/tickets/v1/merchants/${this.merchantId}/members/${this.user.userId}/tickets`
      };
      return this.$http(config);
    },
    getTickets() {
      this.callGetTicketsAPI()
          .then((res) => {
              this.tickets = res.data;
          })
          .catch((err) => console.log(err));
    },
  },
};
</script>

<style scoped>
.version {
  font-size: 8px;
  color: #8080807a;
}
</style>